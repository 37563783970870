import React from 'react';
import { Box } from 'grommet';
import { SbBlokData, storyblokEditable } from 'gatsby-source-storyblok';
import EmbeddedContent from '../EmbeddedContent';

type Props = {
  blok: SbBlokData;
};

export default function DynamicEmbeddedContent({ blok }: Props) {
  const { embedCode } = blok;
  if (!embedCode || typeof embedCode !== 'string') {
    return null;
  }
  return <EmbeddedContent {...storyblokEditable(blok)} embedCode={embedCode} />;
}
